import { useEffect, useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { apiV1 } from "../lib/axios";
import { set } from "react-hook-form";

export const EcheckContext = createContext({});

export function EcheckProvider({ children }) {

  const [echeckLoader, setecheckLoader] = useState(false);
  const [echeck, setecheck] = useState([]);
  const [echeckLayouts, setecheckLayouts] = useState([]);
  const [inputOTP, setInputOTP] = useState("");
  const [echeckData, setEcheckData] = useState({
    layoutId: "",
    clientId: "",
    value: "",
    pixKey: "",
    description: "",
  });
 
  async function fetchEcheck () {
    setecheckLoader(true);

    await apiV1
      .get("/echeque/list")
      .then((response) => {
        setecheck(response.data);
        setecheckLoader(false);
      })
      .catch((err) => {
        
        setecheckLoader(false);
         toast.error('Erro ao buscar e-Cheques');
      });
  }

  async function createEcheque () {
    setecheckLoader(true);
    return await apiV1
      .post("echeque/store", {
        layoutId: echeckData.layoutId,
        clientId: echeckData.clientId,
        value: echeckData.value,
        pixKey: echeckData.pixKey,
        description: echeckData.description
      }).then((response) => {
        setecheckLoader(false);
        toast.success(response.message); 
        return response;
      })
      .catch((err) => {
        
        setecheckLoader(false);
        toast.error('Erro ao salvar eCheque');
      });
  }

  async function sendEcheck () {
    setecheckLoader(true);

    await apiV1
      .post("echeque/transfer",  {
        echeckIndetifier: echeckData.identifier,
        pixKey: echeckData.pixKey
      }).then((response) => {
        setecheckLoader(false);
        toast.success(response.message);
      })
      .catch((err) => {
        
        setecheckLoader(false);
        toast.error('Erro ao salvar eCheque');
      });
  }



  async function sendMfa() {

    return await apiV1
      .post("/echeque/sendMfa", {
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(response.message);
      });
  }

  async function MfaAuthenticate() {

    return await apiV1
      .post("/echeque/sendMfa/authenticate", {
        otp: inputOTP,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error("Erro ao autenticar MFA");
      });
  }

  async function validateTaxNumber (clientId, pixKey) {
    setecheckLoader(true);

   return await apiV1 
      .post("echeque/pixkey/validate", {
        clientId,
        pixKey,
      }).then((response) => {
        setecheckLoader(false);
        toast.success(response.message);
        return response;
      })
      .catch((err) => {
        
        setecheckLoader(false);
        toast.error('A chave informada não corresponde ao CPF/CNPJ do cliente');
      });
  }
  
  async function fetchEcheckLayouts() {

 return await apiV1
      .get("/echeque/layout/list")
      .then((response) => {
        setecheckLayouts(response.data);
        setecheckLoader(false);
        return response.data;
      })
      .catch((err) => {
        
        setecheckLoader(false);
        toast.error('Erro ao buscar layouts');
      });
  }

   async function getEcheckByIdentifier(identifier) {
    const response = await apiV1.post("/echeque/validate", {identifier});
    return response.data;
  }

  const context = {
    echeck,
    echeckLayouts,
    echeckLoader,
    createEcheque,
    fetchEcheck,
    fetchEcheckLayouts,
    validateTaxNumber,
    sendMfa,
    setEcheckData,
    echeckData,
    sendEcheck,
    setInputOTP,
    MfaAuthenticate,
    getEcheckByIdentifier
  };

  return (
    <EcheckContext.Provider
      value={context}
    >
      {children}
    </EcheckContext.Provider>
  );
}

