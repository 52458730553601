import React from "react";
import "./Style.css"
import moment from "moment";
import { MdKeyboardArrowRight } from "react-icons/md";
import bankList from '../../../../services/bankList.json';
import "moment/locale/pt-br";


export default function (props) {

    const hideTaxNumber = () => {
        if(props.data.taxNumber){
            let originalTaxNumber = props.data.taxNumber;
            let formattedTaxNumber;
            if(originalTaxNumber.length === 14){
                formattedTaxNumber = `CNPJ ** *** ${originalTaxNumber.slice(5,8)} /**** - ${originalTaxNumber.slice(-2)}`;
            } else{
                formattedTaxNumber = `CPF *** *** ${originalTaxNumber.slice(6,9)} - ${originalTaxNumber.slice(-2)}`
            }

            return formattedTaxNumber
        }
    }

    const formatValue = (value, status) => {
        if(status == "Recebido"){
            return `R$${value}`;
        } else {
            return `- R$ ${value}`;
        }
    }

    return (
        <div className="historico-pix-item">
            <div className="col-xl-1">
                    <div className="circle"></div>
                    <div className="column"></div>
            </div>

            <div className="col-xl-4 name-area">
                <div>
                    <h2>{props.data.name}</h2>
                    <p>{moment(props.data.date).format('DD [de] MMMM [às] HH:mm')}</p>
                </div>
            </div>
            <div className="col-xl-3 taxnumber-area">
                <p>{hideTaxNumber()}</p>
                <p>{props.data.bank} - {(bankList.filter((item)=>item.code == props.data.bank))[0]?.name} </p>
            </div>
            <div className="col-xl-3">
                <div className="value-area">
                    <h2>{formatValue(props.data.value, props.data.status)}</h2>
                    {/* <div className="d-flex align-items-center">
                        <p>Ver comprovante</p>
                        <MdKeyboardArrowRight size={16}/>
                    </div> */}
                </div>
            </div>

        </div>
    )
}
