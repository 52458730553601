import { toast } from "react-toastify";
import { apiV1 } from "../lib/axios";

export const sendTedFitBank = async ( data ) => {
  const response = await apiV1.post("/ted/send", data)
    .catch((err) => {
      toast.error(`${err.response.data.message} - TD005`);
      return;
    });

  return response.data
}