import { TbCircleKey, TbCopy, TbClipboardCheck, TbTrash } from "react-icons/tb";
import { BsCheckSquare, BsXSquare, BsX } from 'react-icons/bs'
import { toast } from "react-toastify";
import { useState } from "react";
import { pixTypes, pixKeyStatus } from "../../../services/enums";
import { useEffect } from "react";
import { deleteKey } from "../../../services/pix.service";

import './styles.css'
import { useNavigate } from "react-router";
import { InfoStatusButton } from "../../../components/acesso-rapido/pix/pix-key-card/InfoStatusButton";


export function PixKey({ type, keyValue, id, getKeys, status, showConfirmationCodeModal, setTypeKey, startPuller, showKeyDeletedModal, inactiveKeys}) {
  const [isCopied, setIsCopied] = useState(false)
  const [deleteActionsIsOpen, setDeleteActionsIsOpen] = useState(false)
  const [typeValue, setTypeValue] = useState('')
  const [statusValue, setStatusValue] = useState('')
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()

  const getTypeDescription = () =>{
    const typeResult = pixTypes.filter((item)=> item.key == type);
    setTypeValue(typeResult[0].value);
  }
  const getStatusDescription = () =>{
    const statusResult = pixKeyStatus.filter((item)=> item.key == status);
    setStatusValue(statusResult[0].value);
  }

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(keyValue);

      setIsCopied(true)

      setTimeout(() => {
        setIsCopied(false)
      }, 3000);
    } catch(err) {
      toast.error('Não foi possivel copiar a chave pix.')
    }
  }

  function handleCancelDelete() {
    setDeleteActionsIsOpen(false)
  }

  function handleDelete() {
    setLoader(true);
    deleteKey(id, navigate).then(isDeleted => {
      if(isDeleted){
        startPuller();
        ShowKeyDeletedModal();
        setLoader(false);
      }
    })

    setDeleteActionsIsOpen(false)
  }

  useEffect(() => {
    if (type) {
      getTypeDescription()
    }
  }, [type])

  useEffect(() => {
    if (status) {
      getStatusDescription()
    }
  }, [status])
  function ShowConfirmationCodeModal(){
    setTypeKey(type);
    showConfirmationCodeModal()
  }

  function ShowKeyDeletedModal(){
    setTypeKey(type);
    showKeyDeletedModal()
  }
  return (
    <div className="pix-key-container">
      <div className="pix-key-info">


        <div className="pix-key-content">

          <div className="info-header">
            <div className="type-value">{typeValue}</div>
            <span className="separator">-</span>
            <div className="info-status-title">Status:</div>
            <div className="info-status-value">{statusValue}</div>
            <div className="info-status-button">

              {status == PIX_KEY_STATUS_LIST.NOT_CONFIRMED && (<InfoStatusButton click={() => ShowConfirmationCodeModal()} label={'inserir código de confirmação'}/>)}
              {status == PIX_KEY_STATUS_LIST.OWNERSHIP_ERROR && (<InfoStatusButton onClick={()=>{}} label={'solicitar portabilidade'}/>)}
            </div>
          </div>
          <strong>{keyValue}</strong>
        </div>

      </div>

      <div className="pix-key-actions">
        <button onClick={copyToClipboard}>
          {
            isCopied
            ? (
              <TbClipboardCheck className="copy-check" size={24} />
            )
            : (
              <TbCopy size={24} />
            )
          }
        </button>
          {
            loader ? <span>Excluindo...</span> : <>{
              deleteActionsIsOpen
              ? (
                <div className="key-delete-actions">
                  <button className="trash-button" onClick={handleCancelDelete}>
                    <BsXSquare size={24} />
                  </button>
                  <button className="delete-confirm-button" onClick={handleDelete}>
                    <BsCheckSquare size={24} />
                  </button>
                </div>
              ) : (
                <button className="trash-button" onClick={() => setDeleteActionsIsOpen(true)}>
                  <TbTrash size={24} />
                </button>
              )
            }</>
          }

      </div>
    </div>
  )
}

const PIX_KEY_STATUS_LIST = {
  ACTIVE: 0,
  INACTIVE: 1,
  PENDING: 2,
  ERROR: 3,
  PORTABILITY_ERROR: 4,
  OWNERSHIP_ERROR: 5,
  NOT_CONFIRMED: 6,
  CLAIMING: 7,
}
