import { css } from '@emotion/react';
import React, { useState } from 'react';
import { TbCircleCheck, TbKey, TbShare } from 'react-icons/tb';
import ReactModal from 'react-modal';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import bankList from '../../../../services/bankList.json';
import {
  getQrcodeInfoFitBank,
  hideTaxNumber,
  identificarTipoChavePix,
  sendPixFitBank,
} from '../../../../services/pix.service';
import './Style.css';
import { formatValueToMonetary } from '../../../../utils/formatter';

const CopiaeColaModal = ({
  setMostrarCopiaeColaModal,
  mostrarCopiaeColaModal,
}) => {
  const [copiaecolaLoader, setCopiaecolaLoader] = useState(false);
  const [copiaecolaValue, setCopiaecolaValue] = useState('');
  const [copiaecolaResult, setCopiaecolaResult] = useState();
  const [copiaecolaResponse, setCopiaecolaResponse] = useState();

  const [passwordRequired, setPasswordRequired] = useState(false);
  const [transationalPassword, setTransationalPassword] = useState('');

  const { user, account } = useAuth();

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  const closeModal = () => {
    setMostrarCopiaeColaModal(false);
    setCopiaecolaValue('');
    setCopiaecolaResult('');
    setCopiaecolaResponse('');
    setTransationalPassword('');
    setPasswordRequired(false);
  };

  const lerCopiaeCola = async () => {
    try {
    if (user.taxNumber == '12345678900') {
      if (
        copiaecolaValue ==
        '00020126710014br.gov.bcb.pix0136ae42d0d8-b34c-4a8e-afc9-d89f6d43fb9e0209pagamento52040000530398654043.005802BR5925PARCELA X SOLUCOES EM PAG6009Sao Paulo61081360046062290525AE4C79AC5C4924730168F17E363049231'
      ) {
        setCopiaecolaResult({
          ReceiverBank: '450',
          ReceiverName: 'PARCELA X SOLUCOES EM PAGAMENTO LTDA',
          ReceiverTaxNumber: '22007952000170',
          valor: '3',
          chave: 'ae42d0d8-b34c-4a8e-afc9-d89f6d43fb9e',
        });
        return;
      } else {
        toast.error('Código copia e cola inválido');
        return;
      }
    }

    setCopiaecolaLoader(true);

    const qrCodeResponse = await getQrcodeInfoFitBank(copiaecolaValue);
    if (!qrCodeResponse.data) {
      toast.error('Código Copia e Cola Inválido');
      setCopiaecolaLoader(false);
      return;
    }
    setCopiaecolaResult({
      ReceiverBank: qrCodeResponse.data.ReceiverBank,
      ReceiverName: qrCodeResponse.data.ReceiverName,
      ReceiverTaxNumber: qrCodeResponse.data.ReceiverTaxNumber,
      valor:
        qrCodeResponse.data.FinalValue || qrCodeResponse.data.OriginalValue,
      chave: qrCodeResponse.data.PixKeyValue,
    });
    setCopiaecolaLoader(false);
    setPasswordRequired(true);
    return;
  } catch (err) {
    toast.error("Erro ao buscar informações do QRCode");
    setCopiaecolaLoader(false);
    return;
  };
  };

  const pagarCopiaeCola = async () => {
    if (user.taxNumber === '12345678900') {
      setCopiaecolaResponse({
        ReceiverName: 'Teste Beta',
        ReceiverTaxNumber: '36742398000189',
        ReceiverBank: '260',
        valor: '10',
      });
      return;
    }

    if (!passwordRequired) {
      setPasswordRequired(true);
      return;
    }

    if (!transationalPassword) {
      toast.error('Digite a senha');
      return;
    }

    try {
      const chave = copiaecolaResult.chave;
      const type = identificarTipoChavePix(chave);

      const body = {
        value: parseFloat(copiaecolaResult.valor),
        keyType: JSON.stringify(type),
        key: chave,
        transationalPassword,
      };
      setCopiaecolaLoader(true);
      let pixResponse = await sendPixFitBank(body);

      if (pixResponse) {
        setCopiaecolaResponse(pixResponse.data);
        setCopiaecolaResult('');
      }
      setCopiaecolaLoader(false);
      setPasswordRequired(false);
      setTransationalPassword('');
      return;
    } catch (err) {
      toast.error(err.response.data.message);
      setCopiaecolaLoader(false);
      return;
    }
  };

  return (
    <ReactModal
      isOpen={mostrarCopiaeColaModal}
      className={'pix-modal'}
      overlayClassName={'overlay-pix-modal'}
    >
      <div className="copiaecola-modal-container">
        {!copiaecolaResponse ? (
          <>
            <div className="col-md-5">
              {passwordRequired ? (
                <div>
                  <label>Senha</label>
                  <div className="input-area">
                    <div className="input-area-icon col-md-1">
                      <TbKey size={18} />
                    </div>
                    <div className="col-md-11">
                      <input
                        type="password"
                        value={transationalPassword}
                        onChange={(e) => {
                          setTransationalPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <label>Pix Copia e Cola</label>
                  <div className="input-area">
                    <div className="input-area-icon col-md-1">
                      <TbKey size={18} />
                    </div>
                    <div className="col-md-11">
                      <input
                        value={copiaecolaValue}
                        onChange={(e) => {
                          setCopiaecolaValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="button-area">
                <button className="cancel-button" onClick={closeModal}>
                  Cancelar
                </button>

                {!copiaecolaResult ? (
                  <button
                    className="confirm-button"
                    onClick={copiaecolaLoader ? () => {} : lerCopiaeCola}
                  >
                    {copiaecolaLoader ? (
                      <BeatLoader
                        color="var(--text-primary)"
                        loading={copiaecolaLoader}
                        css={override}
                        size={20}
                      />
                    ) : (
                      'Consultar'
                    )}
                  </button>
                ) : (
                  <button className="confirm-button" onClick={pagarCopiaeCola}>
                    {copiaecolaLoader ? (
                      <BeatLoader
                        color="white"
                        loading={copiaecolaLoader}
                        css={override}
                        size={20}
                      />
                    ) : (
                      'Confirmar'
                    )}
                  </button>
                )}
              </div>
            </div>

            <div className="col-md-5">
              {copiaecolaResult && (
                <>
                  <div>
                    <h1>Resumo da Transferência</h1>
                  </div>

                  <div>
                    <div className="receipt-items">
                      <label>Nome</label>
                      <h4>{copiaecolaResult.ReceiverName}</h4>
                    </div>

                    <div className="receipt-items">
                      <label>CPF / CNPJ</label>
                      <h4>{copiaecolaResult.ReceiverTaxNumber}</h4>
                    </div>

                    <div className="receipt-items">
                      <label>Banco</label>
                      <h4>
                        {copiaecolaResult.ReceiverBank} -{' '}
                        {
                          bankList.filter(
                            (item) => item.code == copiaecolaResult.ReceiverBank
                          )[0]?.name
                        }
                      </h4>
                    </div>

                    <div className="receipt-items">
                      <label>Valor</label>
                      <h4>{formatValueToMonetary(Number(copiaecolaResult.valor))}</h4>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div>
            <div className="receipt-header">
              <TbCircleCheck color="#32D16D" size={75} />
              <h1>Transferência Pix realizada com sucesso!</h1>
            </div>

            <div>
              <button className="close-button" onClick={closeModal}>
                X
              </button>
            </div>

            <div className="receipt-body">
              <div className="d-flex">
                <div className="col-md-4">
                  <label>Nome</label>
                  <p>{copiaecolaResponse?.toName}</p>
                </div>
                <div className="col-md-4">
                  <label>Instituição de Destino</label>
                  <p>
                    {copiaecolaResponse?.toBank} -{' '}
                    {
                      bankList.filter(
                        (item) => item.code == copiaecolaResponse?.toBank
                      )[0]?.name
                    }
                  </p>
                </div>
                <div className="col-md-4">
                  <label>CPF/CNPJ do Favorecido</label>
                  <p>{hideTaxNumber(copiaecolaResponse?.toTaxNumber)}</p>
                </div>
              </div>
            </div>

            <div className="receipt-footer">
              <button className="share-button">
                Compartilhar comprovante
                <TbShare size={16} />
              </button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer/>
    </ReactModal>
  );
};

export default CopiaeColaModal;
