import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { TiContacts } from "react-icons/ti";
import { PageHeader } from "../../../page-header";
import { Contato } from "../../../contato";
import { useTed } from '../../../../hooks/useTed'
import { getAvatarChar, getBankName } from "../../../../utils/formatter";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";

import "./styles.css";

const PaginaTed = () => {
  const navigate = useNavigate()

  const {
    favorites,
    fetchFavorites,
    favoritesLoader,
    setAgencia,
    setConta,
    setDigito,
    setTipoConta,
    setCpf,
    setNome,
    setBanco,
    setAlreadyFavorites,
  } = useTed()

  function handleToTransfer(data) {
    setAgencia(data.bankBranch)
    setConta(data.bankAccount)
    setDigito(data.bankAccountDigit)
    setTipoConta(data.accountType)
    setCpf(data.taxNumber)
    setNome(data.name)
    setBanco(data.bank)
    setAlreadyFavorites(true),

    navigate('/pagina-transferencia')
  }

  function clearInputsTransfer() {
    setAgencia("")
    setConta("")
    setDigito("")
    setTipoConta("CC")
    setCpf("")
    setNome("")
    setBanco("")
    setAlreadyFavorites(false)
  }

  useEffect(() => {
    fetchFavorites()
  }, [])

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className="pagina-ted-container">
      <div className="pagina-ted-header">
        <PageHeader title="TED" pages={[{ name: 'TED', link: "" }]} />
      </div>

      <div className="pagina-ted-content">
        <div className="pagina-ted-content-actions">
          <button disabled title="Em Breve" className="btn-contatos-disabled">
            <TiContacts size={16} />
            Contatos
          </button>

          <NavLink
            onClick={clearInputsTransfer}
            to="/pagina-transferencia"
            className="content-actions-button-red"
          >
            Nova Transferência TED
          </NavLink>
        </div>

        <div className="ted-contatos-container">
          <span>Contatos Recentes</span>

          <div className="ted-contatos-list">
            {
              favoritesLoader
              ? (
                <BeatLoader
                  color={"var(--secondary-color)"}
                  loading={favoritesLoader}
                  css={override}
                  size={20}
                />
              ) : (
                <>
                  {
                    favorites.length
                    ? (
                      <>
                        {favorites.map((item, index) => (
                          <button
                            key={index}
                            onClick={() => handleToTransfer(item)}
                          >
                            <Contato
                              avatar={item.name ? getAvatarChar(item.name) : ''}
                              name={item.name}
                              bank={getBankName(item.bank)}
                            />
                          </button>
                        ))}
                      </>
                    ) : (
                      <span>Sem contatos favoritos.</span>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaginaTed;
