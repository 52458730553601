export const pixTypes = [
  {
    key: 0,
    value: "CPF",
  },
  {
    key: 1,
    value: "CNPJ",
  },
  {
    key: 2,
    value: "Email",
  },
  {
    key: 3,
    value: "Telefone",
  },
  {
    key: 4,
    value: "Chave Aleatória",
  },
];

export const pixKeyStatus = [
  {
    key: 0,
    value: "Ativa",
  },
  {
    key: 1,
    value: "Inativa",
  },
  {
    key: 2,
    value: "Pendente",
  },
  {
    key: 3,
    value: "Erro",
  },
  {
    key: 4,
    value: "Erro de portabilidade",
  },
  {
    key: 5,
    value: "Erro de Titularidade",
  },
  {
    key: 6,
    value: "Não Confirmada",
  },
  {
    key: 7,
    value: "Reivindicando",
  },
]

export const entryTypes = [
  {
    key: "FinalBalance",
    displayValue: "Saldo Final",
  },
  {
    key: "InitialBalance",
    displayValue: "Saldo Inicial",
  },
  {
    key: "CreditPixIn",
    displayValue: "Pix Recebido",
  },
  {
    key: "RatePixIn",
    displayValue: "Taxa de Recebimento de Pix",
  },
  {
    key: "PreDebitPixOut",
    displayValue: "Pix Enviado",
  },
  {
    key: "e-Check compensado",
    displayValue: "e-Cheque compensado", 
  },
  {
    key: "CreditRefundPixOut",
    displayValue: "Devolução Pix",
  },
  {
    key: "Transfer",
    displayValue: "Transferencia por TED",
  },
  {
    key: "PreDischargeAccount",
    displayValue: "Transferência por TED",
  },
  {
    key: "PreDebitBoletoOut",
    displayValue: "Pagamento de Boleto",
  },
  {
    key: "CreditTedIn",
    displayValue: "Recebimento por TED",
  },
  {
    key: "RateTedIn",
    displayValue: "Taxa de Recebimento de TED",
  },
  {
    key: "PreRateByPixOut",
    displayValue: "taxa de Envio de Pix",
  },
  {
    key: "Pix",
    displayValue: "Pix Enviado",
  },
  {
    key: "TARIFA_PIX",
    displayValue: "Taxa de Envio de Pix",
  },
  {
    key: "STR0008",
    displayValue: "Transferencia por TED",
  },
  {
    key: "TAXA_TED",
    displayValue: "Taxa de Envio de TED",
  },
  {
    key: "Boleto",
    displayValue: "Pagamento de Boleto",
  },
  {
    key: "RateInternalDischargeAccount",
    displayValue: "Taxa de Transferencia",
  },
  {
    key: "RateByBoleto",
    displayValue: "Taxa de recebimento de boleto",
  },
  {
    key: "CreditBoleto",
    displayValue: "Confirmação de pgto de boleto",
  },
  {
    key: "Taxa de recebimento de boleto",
    displayValue: "Taxa de recebimento de boleto",
  },
  {
    key: "Confirmação de pgto de boleto",
    displayValue: "Confirmação de pgto de boleto",
  },
  {
    key: "Pré-taxa para transferencia",
    displayValue: "Pré-taxa para transferencia",
  },

  {
    key: "TED enviado",
    displayValue: "Transferência por TED",
  },
  {
    key: "Pix enviado",
    displayValue: "Pix Enviado",
  },
  {
    key: "Pagamento de boleto",
    displayValue: "Pagamento de Boleto",
  },
  {
    key: "Taxa de envio de TED",
    displayValue: "Taxa de Envio de TED",
  },
  {
    key: "Taxa de envio de Pix",
    displayValue: "Taxa de Envio de Pix",
  },
  {
    key: "Pix enviado",
    displayValue: "Pix Enviado",
  },
  {
    key: "TED enviado",
    displayValue: "Transferência por TED",
  },
  {
    key: "Taxa de envio de Pix",
    displayValue: "taxa de Envio de Pix",
  },
  {
    key: "Taxa de recebimento de TED",
    displayValue: "Taxa de Recebimento de TED",
  },
  {
    key: "Taxa de recebimento de Pix",
    displayValue: "Taxa de Recebimento de Pix",
  },
  {
    key: "Taxa de emissão de boleto",
    displayValue: "Taxa de recebimento de boleto",
  },
  {
    key: "Pagamento de boleto",
    displayValue: "Pagamento de Boleto",
  },
  {
    key: "Estorno de Pix",
    displayValue: "Devolução Pix",
  },
  {
    key: "TED recebido",
    displayValue: "Recebimento por TED",
  },
  {
    key: "Pix recebido",
    displayValue: "Pix Recebido",
  },
  {
    key: "Estorno de Pagamento Boleto",
    displayValue: "Estorno de Pagamento Boleto",
  },
  {
    key: "Estorno de Pagamento TED",
    displayValue: "Estorno de Pagamento TED",
  },
  {
    key: "Estorno de Pagamento Pix",
    displayValue: "Estorno de Pagamento Pix",
  },
  {
    key: "Estorno de TED",
    displayValue: "Estorno de TED",
  },
  {
    key: 'CancelRateTransferPIX',
    displayValue :'Cancelamento da Taxa de PIX'
  },
  {
    key: 'CancelRateTransfer',
    displayValue :'Cancelamento da Taxa de TED'
  },
  {
    key: 'Cancelamento da Taxa de PIX',
    displayValue :'Cancelamento da Taxa de PIX'
  },
  {
    key: 'Cancelamento da Taxa de TED',
    displayValue :'Cancelamento da Taxa de TED'
  },
  {
    key: 'e-Check compensado',
    displayValue :'e-Cheque Compensado'
  },
  {
    key: 'BoletoWithOverpaid',
    displayValue :'Confirmação de pgto de boleto'
  },
  {
    key: 'ChargeAccount',
    displayValue :'Cobrança de Mensalidade'
  },
  
];

export const documentTypes = {
  rgFrente : 0,
  cpf : 1,
  compResidencia : 2,
  CNHFrente : 3,
  CNPJ : 4,
  socialContract : 5,
  letterOfAttorney : 6,
  rgVerso : 7,
  constitutiveAct : 11,
  selfie : 14,
  CNHVerso : 16,
  compRenda : 17,
}
