import React from "react";
import './Style.css';
import { pixTypes } from "../../../../services/enums";
import { useState } from "react";


export const InfoStatusButton = (props) => {
  function click() {
    props.click()
  }
  return(
    <button onClick={() => click()}>{props.label}</button>
  );
}
