import React, { useRef, useState } from "react";
import { formatCnpjCpf } from '../../../utils/formatter'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";

import "../desc-extrato/Style.css";
import "moment/locale/pt-br";

export default function (props) {
  const [loader, setLoader] = useState(false);

  const pdfRef = useRef(null);

  const pdfDownload = () => {
    setLoader(true)

    const capture = pdfRef.current;
    capture.classList.add("details-container-dark");

    html2canvas(capture).then(canvas => {
      capture.classList.remove("details-container-dark");
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('transaction.pdf')
      setLoader(false)
      toast.success('PDF baixado com sucesso.')
    })
  }

  const pdfGenerate = async () => {
    setLoader(true)

    const capture = pdfRef.current;
    capture.classList.add("details-container-dark");

    await html2canvas(pdfRef.current).then(canvas => {
      capture.classList.remove("details-container-dark");
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      window.open(pdf.output('bloburl', {
        filename: 'transction.pdf'
      }))
      setLoader(false)
    })
  }

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;

  return (
    <div className="entry-details">
      {
        loader
        ? (
          <div className="details-container-loading">
            <BeatLoader
              color={"var(--secondary-color)"}
              loading={loader}
              css={override}
              size={20}
            />
          </div>
        ) : (
          <div ref={pdfRef} className="details-container">
            <div>
              <h1 className="title">Detalhamento de Informações</h1>
            </div>
            <div className="d-flex">
              <div className="col-md-6 origem">
                <h1>Origem</h1>

                <div>
                  <label>Nome</label>
                  <p>{props.data.payerName || "Nome não informado"}</p>
                </div>

                <div>
                  <label>CPF/CNPJ</label>
                  <p>{props.data.payerTaxNumber ? formatCnpjCpf(props.data.payerTaxNumber) : "CPF/CNPJ não informado"}</p>
                </div>

                <div>
                  <label>Instituição</label>
                  <p>{props.data.payerBank || "Banco não informado"}</p>
                </div>

                <div>
                  <label>Agência</label>
                  <p>{props.data.payerBankBranch || "Agência não informada"}</p>
                </div>

                <div>
                  <label>Conta</label>
                  <p>
                    {(props.data.payerBankAccount && props.data.payerBankAccountDigit)
                      ? props.data.payerBankAccount + " - " + props.data.payerBankAccountDigit
                      : "Conta não informada"}
                  </p>
                </div>
              </div>
              
              <div className="col-md-6 destino">
                <h1>Destino</h1>

                <div>
                  <label>Nome</label>
                  <p>{props.data.receiverName || "Nome não informado"}</p>
                </div>

                <div>
                  <label>CPF/CNPJ</label>
                  <p>{props.data.receiverTaxNumber ? formatCnpjCpf(props.data.receiverTaxNumber) : "CPF/CNPJ não informado"}</p>
                </div>

                <div>
                  <label>Instituição</label>
                  <p>{props.data.receiverBank || "Banco não informado"}</p>
                </div>

                <div>
                  <label>Agência</label>
                  <p>{props.data.receiverBankBranch || "Agência não informada"}</p>
                </div>

                <div>
                  <label>Conta</label>
                  <p>
                    {(props.data.receiverBankAccount && props.data.receiverBankAccountDigit)
                      ? props.data.receiverBankAccount + " - " +  props.data.receiverBankAccountDigit
                      : "Conta não informada"}
                  </p>
                </div>
              </div>
            </div>
            <hr/>
            <div>
              <div className="col-md-12">
                <h1>Dados gerais do pagamento</h1>

                <div>
                  <label>Identificador</label>
                  <p>{props.data.identifier}</p>
                </div>

                {props.data.barCode && (
                  <div>
                    <label>Código de barras</label>
                    <p>{props.data.barCode}</p>
                  </div>
                )}

                <div>
                  <label>Valor</label>
                  <p>R$ {String(props.data.value).replaceAll('.', ',')}</p>
                </div>
              </div>
            </div>
            <hr/>
          </div>
        )
      }
      <div className="desc-actions-container">
        <button onClick={props.closeDetails}>Voltar</button>
        {
          loader
          ? (
            <button className="loading-btn" disabled>Gerando PDF...</button>
          )
          : (
            <button onClick={pdfGenerate}>Gerar PDF</button>
          )
        }
        {
          loader
          ? (
            <button className="loading-btn" disabled>Baixando PDF...</button>
          )
          : (
            <button onClick={pdfDownload}>Baixar PDF</button>
          )
        } 
      </div>
    </div>
  );
}
