import { css } from "@emotion/react";
import React, { useCallback, useEffect, useState } from "react";
import IntlCurrencyInput from "react-intl-currency-input";
import { Link, NavLink, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { apiV1 } from "../../../../lib/axios";
import bankList from "../../../../services/bankList.json";
import {
  getPixInfo,
  listContacts,
  listPix,
  sendPixFitBank,
} from "../../../../services/pix.service";
import "./Style.css";

import {
  MdKeyboardArrowRight,
  MdOutlineReportGmailerrorred,
} from "react-icons/md";
import { TbCircleCheck, TbCoin, TbKey, TbShare } from "react-icons/tb";
import { TiContacts } from "react-icons/ti";
import ReactModal from "react-modal";
import { useAccount } from "../../../../hooks/useAccount";
import { useAuth } from "../../../../hooks/useAuth";
import { getImage } from "../../../../services/getAssets";
import HistoricoPixRow from "../historico-pix-row/HistoricoPixRow";

export default function () {
  const location = useLocation();
  const [valor, setValor] = useState(0);
  const [chave, setChave] = useState("");
  const [response, setResponse] = useState({});
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [transationalPassword, setTransationalPassword] = useState("");
  const [isInvalidKey, setIsInvalidKey] = useState(false);
  const [recentPixes, setRecentPixes] = useState();
  const [contacts, setContacts] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const [isFavorite, setisFavorite] = useState();
  const [alreadyInFavorites, setAlreadyInFavorites] = useState(false);
  const [receipt, setReceipt] = useState(false);
  const [preReceit, setPreReceipt] = useState();
  const [hasPreReceipt, setHasPreReceipt] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [sendPixLoader, setSendPixLoader] = useState(false);
  const [getInfoLoader, setGetInfoLoader] = useState(false);
  const [historicLoader, setHistoricLoader] = useState(false);

  const { user, getProfile } = useAuth();
  const { avaliableBalance, getBalance } = useAccount();
  const confirmText = passwordRequired ? "Confirmar senha" : "Confirmar Pix";

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    display: flex;
  `;

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();

    setErrorMessage('');
    setValor(value);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setHasPreReceipt(false);
    setPreReceipt();
    setReceipt();
    setTransationalPassword();
    setPasswordRequired(false);
    setChave("");
    setValor(0);
    setGetInfoLoader(false);
    setSendPixLoader(false);
  };

  const hideTaxNumber = (taxNumber) => {
    if (taxNumber) {
      let originalTaxNumber = taxNumber.toString();
      let formattedTaxNumber;
      if (originalTaxNumber.length === 14) {
        formattedTaxNumber = `** *** ${originalTaxNumber.slice(
          5,
          8
        )} /**** - ${originalTaxNumber.slice(-2)}`;
      } else {
        formattedTaxNumber = `*** *** ${originalTaxNumber.slice(
          6,
          9
        )} - ${originalTaxNumber.slice(-2)}`;
      }
      return formattedTaxNumber;
    }
  };

  const validaCpf = (cpf) => {
    const cpfStr = cpf.replace(/[^\d]+/g, "");
    // Verifica se a variável cpf está em branco ou é igual a "undefined", exibindo uma msg de erro
    if (cpfStr === "" || cpfStr === undefined) {
      return false;
    }
    // Elimina CPFs invalidos conhecidos
    if (
      cpfStr.length !== 11 ||
      cpfStr === "00000000000" ||
      cpfStr === "11111111111" ||
      cpfStr === "22222222222" ||
      cpfStr === "33333333333" ||
      cpfStr === "44444444444" ||
      cpfStr === "55555555555" ||
      cpfStr === "66666666666" ||
      cpfStr === "77777777777" ||
      cpfStr === "88888888888" ||
      cpfStr === "99999999999"
    ) {
      return false;
    }

    // Valida 1o digito
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpfStr.charAt(i)) * (10 - i);
    }
    let resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpfStr.charAt(9))) {
      return false;
    }

    // Valida 2o digito
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpfStr.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpfStr.charAt(10))) {
      return false;
    }
    return true;
  };

  function identificarTipoChavePix(chave) {
    const regexCNPJ = /^(\d{14}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/;
    const regexTelefone = /^(\+\d{2}(\d{2}|\d{3})\d{8,9}|\d{11,12})$/;
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (validaCpf(chave)) {
      return 1; // Chave PIX do tipo CPF
    } else if (regexCNPJ.test(chave)) {
      return 1; // Chave PIX do tipo CNPJ
    } else if (regexEmail.test(chave)) {
      return 2; // Chave PIX do tipo e-mail
    } else if (regexTelefone.test(chave)) {
      return 3; // Chave PIX do tipo telefone
    } else {
      return 4; // Chave PIX com formato desconhecido ou chave aleatória
    }
  }

  async function verifyFavorite(data) {
    const response = await listContacts();
    
    if(!response?.data?.rows) return;

    const contacts = response.data.rows;

    const alreadyFavorite = contacts.find(
      (item) => item.key === data.PixKeyValue
    );

    if (alreadyFavorite) {
      setAlreadyInFavorites(true);
    } else {
      setAlreadyInFavorites(false);
    }
  }

  const getInfos = useCallback(async (chave) => {
    if(user.taxNumber === '12345678900'){
      setPreReceipt({
        "ReceiverBankName": "Nu Pagamentos S.A",
        "ReceiverName": "Conta Teste",
        "ReceiverISPB": "18236120",
        "ReceiverBank": "260",
        "ReceiverBankBranch": "1",
        "ReceiverBankAccount": "1231232",
        "ReceiverBankAccountDigit": "3",
        "ReceiverAccountType": "1",
        "PixKeyType": "1",
        "PixKeyValue": "12345678900",
        "ReceiverTaxNumber": "12345678900"
      });
      setIsOpenModal(true);
      setGetInfoLoader(false);
      setIsInvalidKey(false);
      return;
    }

    setGetInfoLoader(true);
    if (chave == "") {
      toast.error("Insira a chave pix!");
      setGetInfoLoader(false);
      return;
    }

    try {
      let response = await getPixInfo(chave, identificarTipoChavePix(chave));
      let preReceipt = response.data.data;
 console.log("preReceipt ", preReceipt);

      setPreReceipt(preReceipt);
      await verifyFavorite(preReceipt);
      setIsOpenModal(true);
      setGetInfoLoader(false);
      setIsInvalidKey(false);
    } catch (err) {
 console.log("err ", err);
      setGetInfoLoader(false);
      setIsInvalidKey(true);
    }
  }, []);

  async function sendpix() {

    if (!passwordRequired) {
      if (!valor) {
        return setErrorMessage("Digite o valor *");
      }

      if(valor > avaliableBalance){
        return setErrorMessage("O valor excede o saldo disponível *");
      }

      if (
        user.account.pixLimits &&
        valor > user.account.pixLimits.transactionMaxValue
      ) {
        return setErrorMessage("O valor excede o limite de pix por transferência! PX005");
      }

      setPasswordRequired(true);

      return;
    }

    if (!transationalPassword) {
      toast.error("Digite a senha");
      return;
    }

    if(user.taxNumber === '12345678900') {
      setResponse({
        toName: "Teste",
        toBank: "260",
        toTaxNumber: "16440384716",
      });
      setReceipt(true);
      return;
    }

    const body = {
      value: valor,
      keyType: preReceit.PixKeyType,
      key: preReceit.PixKeyValue,
      toTaxNumber: preReceit.ReceiverTaxNumber,
      isPixFavorito: isFavorite,
      transationalPassword,
    };

    setSendPixLoader(true);
    setReceipt("");

    try {
      let pixResponse = await sendPixFitBank(body);

      if (pixResponse) {
        let response = pixResponse.data;
        setResponse(response);
        await getComprovante({
          transferDate: response.createdAt,
          type: "pix",
          receiverName: response.toName,
          receiverTaxNumber: response.toTaxNumber,
          pixKey: response.toPixKey,
          receiverBank: `${preReceit?.ReceiverBank} - ${
            bankList.filter((item) => item.code == preReceit?.ReceiverBank)[0]
              ?.name
          }`,
          receiverBankBranch: response.toBankBranch,
          receiverBankAccount: response.toBankAccount,
          receiverBankAccountDigit: response.toBankAccountDigit,
          payerName: user.name,
          payerTaxNumber: user.taxNumber,
          payerBank: user.account.bank,
          payerBankBranch: user.account.bankBranch,
          payerBankAccount: user.account.bankAccount,
          payerBankAccountDigit: user.account.bankAccountDigit,
          identifier: response.toBankBranch,
          value: valor,
          transactionId: response.id,
          urlLogo: getImage("logo-preto.png"),
        });

        setReceipt(true);
        setSendPixLoader(false);
      }

      setSendPixLoader(false);
    } catch (err) {
      toast.error(
        "Não foi possivel realizar a transferência, tente novamente! PX005"
      );
      setSendPixLoader(false);
    }
  }

  async function getComprovante(data) {
    await apiV1.post("/voucher/generate", data).then((response) => {
      const res = response.data.data;

      localStorage.removeItem("comprovante");
      localStorage.setItem("comprovante", res.base64);
    });
  }

  useEffect(() => {
    const getSentPixs = async () => {
      if(user.taxNumber === '12345678900'){
        setRecentPixes();
        return;
      }
      setHistoricLoader(true);
      const sentPixs = await listPix();
      setRecentPixes(sentPixs.data);
      setHistoricLoader(false);
    };

    const getContacts = async () => {
      const contacts = await listContacts();
      setContacts(contacts.data);
    };

    if (location.state) {
      if (location.state.pixKey) {
        setChave(location.state.pixKey);
        getInfos(location.state.pixKey);
      } else {
        toast.warn("Falha ao tentar enviar PIX para este contato.");
      }
    }

    getContacts();
    getSentPixs();
  }, [location, toast]);

  useEffect(() => {
    if (!user.id) {
      getProfile();
    }
  }, [user]);

  useEffect(() => {
    if (!avaliableBalance) {
      getBalance();
    }
  }, []);

  return (
    <>
      <div className="transferencia-pix">
        <div className="col-md-10 transferencia-pix-container">
          <div className="header">
            <div className="col-xl-12">
              <label>
                <Link to={"/pagina-principal"}>
                  Pagina inicial
                  <MdKeyboardArrowRight size={20} />
                </Link>
                <Link to={"/pagina-pix"}>
                  Área Pix
                  <MdKeyboardArrowRight size={20} />
                </Link>
                Transferência Pix
              </label>
              <p>Transferência Pix</p>
            </div>
          </div>

          <div className="body">
            <div className="col-md-12">
              <div className="col-md-12 d-flex align-items-end gap-4">
                <div className="col-md-6">
                  <label>Chave Pix</label>
                  <div
                    className={`input-area ${
                      isInvalidKey ? "invalid-input" : ""
                    }`}
                  >
                    <div className="input-area-icon">
                      <TbKey size={18} />
                    </div>
                    <div className="col-md-11">
                      <input
                        type="text"
                        value={chave}
                        placeholder="CPF, CNPJ, Celular ou Chave Aleatória"
                        onChange={(e) => {
                          setChave(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex gap-3">
                  <button disabled title="Em Breve" className="btn-contatos-disabled">
                    <TiContacts size={16} />
                    Contatos
                  </button>
                  <button
                    disabled={getInfoLoader}
                    onClick={() => getInfos(chave)}
                  >
                    {getInfoLoader ? (
                      <BeatLoader
                        color="var(--text-primary)"
                        loading={getInfoLoader}
                        css={override}
                        size={20}
                      />
                    ) : (
                      "Prosseguir"
                    )}
                  </button>
                </div>
              </div>
              <label
                className={`error-message ${
                  isInvalidKey ? "invalid" : "valid"
                }`}
              >
                <MdOutlineReportGmailerrorred size={14} />
                Chave pix não identificada
              </label>

              <div className="col-md-12 mt-5">
                <h2>Contatos Recentes</h2>
                <div className="d-flex">
                  {contacts &&
                    contacts.map((item, index) => {
                      return (
                        <div
                          className="recent-card"
                          key={index}
                          onClick={() => {
                            setAlreadyInFavorites(true);
                            getInfos(item.key);
                          }}
                        >
                          <div className="recent-icons">
                            {item.name.split(" ").length > 1
                              ? item.name.split(" ")[0].split("")[0] +
                                item.name.split(" ")[1].split("")[0]
                              : item.name.split(" ")[0].split("")[0]}
                          </div>
                          <div className="recent-content">
                            <p>{item.name}</p>
                            <span>{item.bank}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <h2>Últimas Transações</h2>

            <div className="historico-area">
              <BeatLoader
                color={"var(--secondary-color)"}
                loading={historicLoader}
                css={override}
                size={20}
              />
              {recentPixes &&
                recentPixes.map((item) => (
                  <HistoricoPixRow
                    key={item.id}
                    data={{
                      name: item.receiverName,
                      taxNumber: item.receiverTaxNumber,
                      date: item.date,
                      value: item.value,
                      bank: item.receiverBank,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={2} />
      <ReactModal
        isOpen={isOpenModal}
        className={"pix-modal"}
        overlayClassName={"overlay-pix-modal"}
      >
        {!receipt ? (
          <>
            <div className="pix-modal-header">
              <h1>Dados do Favorecido</h1>
            </div>

            <div className="pix-modal-body">
              <div className="d-flex">
                <div className="col-md-4">
                  <label>Nome</label>
                  <p>{preReceit?.ReceiverName}</p>
                </div>
                <div className="col-md-4">
                  <label>Instituição de Destino</label>
                  <p>
                    {preReceit?.ReceiverBank} -{" "}
                    {
                      bankList.filter(
                        (item) => item.code == preReceit?.ReceiverBank
                      )[0]?.name
                    }{" "}
                  </p>
                </div>
                <div className="col-md-4">
                  <label>CPF/CNPJ do Favorecido</label>
                  <p>{hideTaxNumber(preReceit?.ReceiverTaxNumber)}</p>
                </div>
              </div>

              <div className="col-md-7">
                {!passwordRequired ? (
                  <>
                    <label>Valor de Transferência</label>
                    <div className="input-area input-pix-currency">
                      <div className="input-area-icon col-md-1">
                        <TbCoin size={18} />
                      </div>
                      <div className="col-md-11">
                        <IntlCurrencyInput
                          currency="BRL"
                          config={currencyConfig}
                          onChange={handleChange}
                          value={valor}
                        />
                      </div>
                    </div>
                    {!alreadyInFavorites && (
                      <div className="d-flex gap-1 mt-2">
                        <input
                          type="checkbox"
                          id="favoritePix"
                          onChange={() => {
                            setisFavorite(!isFavorite);
                          }}
                        />
                        <label htmlFor="favoritePix">Salvar nos contatos</label>
                      </div>
                    )}
                    {
                      errorMessage && (
                        <p className="errorMessage">{errorMessage}</p>
                      )
                    }
                  </>
                ) : (
                  <>
                    <label>Senha</label>
                    <div className="input-area">
                      <div className="input-area-icon col-md-1">
                        <TbKey size={18} />
                      </div>
                      <div className="col-md-11">
                        <input
                          type="password"
                          value={transationalPassword}
                          onChange={(e) => {
                            setTransationalPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="pix-modal-footer">
              <button
                className="cancel-button"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancelar
              </button>
              <button
                className="confirm-button"
                onClick={() => (sendPixLoader ? {} : sendpix())}
              >
                {sendPixLoader ? (
                  <BeatLoader
                    color="var(--text-primary)"
                    loading={sendPixLoader}
                    css={override}
                    size={20}
                  />
                ) : (
                  confirmText
                )}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="pix-modal-header">
              <TbCircleCheck color="#32D16D" size={75} />
              <h1>Transferência Pix realizada com sucesso!</h1>
            </div>

            <div>
              <button className="close-button" onClick={closeModal}>
                X
              </button>
            </div>

            <div className="pix-modal-body">
              <div className="d-flex">
                <div className="col-md-4">
                  <label>Nome</label>
                  <p>{response?.toName}</p>
                </div>
                <div className="col-md-4">
                  <label>Instituição de Destino</label>
                  <p>
                    {response?.toBank} -{" "}
                    {
                      bankList.filter(
                        (item) => item.code == response?.toBank
                      )[0]?.name
                    }
                  </p>
                </div>
                <div className="col-md-4">
                  <label>CPF/CNPJ do Favorecido</label>
                  <p>{hideTaxNumber(response?.toTaxNumber)}</p>
                </div>
              </div>
            </div>

            <div className="pix-modal-footer">
              <NavLink
                to="/comprovante"
                target="_blank"
                className="share-button"
              >
                Compartilhar comprovante
                <TbShare size={16} />
              </NavLink>
            </div>
          </>
        )}
      </ReactModal>
    </>
  );
}
