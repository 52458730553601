import React from "react";

import "../../components/acesso-rapido/Style.css";

import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

/* icones */

import { TbHome, TbFileInvoice, TbReceipt2 } from "react-icons/tb";
import { BiTransferAlt } from "react-icons/bi";
import { BsGearFill, BsChatRightDotsFill } from "react-icons/bs";
import { MdPix } from 'react-icons/md'
import { LiaMoneyBillSolid } from "react-icons/lia";

export default function () {
  const { logout } = useAuth();

  function handleLogout() {
    logout();
  }

  return (
    <div className="componente-acesso">
      <div className="acesso-rapido">
        <h1>MENU</h1>
      </div>

      <div className="scroll">
        <NavLink to={"/pagina-principal"} className="config-button">
          <div className="tipo-acesso">
            <div className="col-md-2 link-icon">
              <TbHome size={17}/>
            </div>

            <div className="col-xl-7">
              <p>Pagina Principal</p>
            </div>
          </div>
        </NavLink>

        <NavLink to={"/pagina-extrato"} className="config-button">
          <div className="tipo-acesso">
            <div className="col-md-2 link-icon">
              <TbFileInvoice size={17}/>
            </div>

            <div className="col-xl-7">
              <p>Extrato</p>
            </div>
          </div>
        </NavLink>

        <NavLink to={"/pagina-pix"} className="config-button">
          <div className="tipo-acesso">
            <div className="col-md-2 link-icon">
              <MdPix size={17}/>
            </div>

            <div className="col-xl-7">
              <p>Pix</p>
            </div>
          </div>
        </NavLink>

        <NavLink to={"/pagina-echeque"} className="config-button">
          <div className="tipo-acesso">
            <div className="col-md-2 link-icon">
              <LiaMoneyBillSolid  size={17}/>
            </div>

            <div className="col-xl-7">
              <p>e-Cheque</p>
            </div>
          </div>
        </NavLink>

        {/* <Link to={"/pagina-pagar"}>
          <div className="tipo-acesso" >
              <div className="col-md-2 link-icon">
                < IoIosWallet />
              </div>

              <div className="col-xl-7">
                <p>Pagar</p>
              </div>
          </div>
        </Link> */}

        {/*
        <Link to={"/pagina-cartoes"}>
          <div className="tipo-acesso" >
              <div className="col-md-2 link-icon">
                <BsCreditCard2Back />
              </div>

              <div className="col-xl-7">
                <p>Cartões</p>
              </div>
          </div>
        </Link> */}

        {/* <Link to={"/pagina-deposito"}>
          <div className="tipo-acesso" >
              <div className="col-md-2 link-icon">
                <MdOutlineQrCode2 />
              </div>

              <div className="col-xl-7">
                <p>Cobrar</p>
              </div>
          </div>
        </Link> */}

        <NavLink to={"/pagina-ted"} className="config-button">
          <div className="tipo-acesso">
            <div className="col-md-2 link-icon">
              <BiTransferAlt size={17} />
            </div>

            <div className="col-xl-7">
              <p>TED</p>
            </div>
          </div>
        </NavLink>

        {/* <Link to={"/pagina-deposito"}>
          <div className="tipo-acesso" >
              <div className="col-md-2">
                <AiOutlineArrowDown />
              </div>

              <div className="col-xl-7">
                <p>Depositar</p>
              </div>
          </div>
        </Link> */}

        {/* <Link to={""}>
          <div className="tipo-acesso" >
              <div className="col-md-2">
                <FaMoneyBillWave />
              </div>

              <div className="col-xl-7">
                <p>Emprestimos</p>
              </div>
          </div>
        </Link> */}

        <NavLink to={"/pagina-boleto"} className="config-button">
          <div className="tipo-acesso">
            <div className="col-md-2 link-icon">
              <TbReceipt2 />
            </div>

            <div className="col-xl-7">
              <p>Boleto</p>
            </div>
          </div>
        </NavLink>
        {/*
        <Link to={"/pagina-pagar-boleto"}>
          <div className="tipo-acesso" >
              <div className="col-md-2">
                <BsUpcScan />
              </div>

              <div className="col-xl-7">
                <p>Pagar</p>
              </div>
          </div>
        </Link> */}
      </div>

      <div className="acesso-rapido">
        <h1>OUTROS</h1>
      </div>

      <NavLink to="/configuracoes" className="config-button">
        <div className="tipo-acesso">
          <div className="col-md-2 link-icon">
            <BsGearFill size={17} />
          </div>

          <div className="col-xl-7">
            <p>Configurações</p>
          </div>
        </div>
      </NavLink>

      <NavLink to="/contato" className="config-button">
        <div className="tipo-acesso">
          <div className="col-md-2 link-icon">
            <BsChatRightDotsFill size={17} />
          </div>

          <div className="col-xl-7">
            <p>Contato</p>
          </div>
        </div>
      </NavLink>

      <div className="button-area">
        <button className="btn btn-default" onClick={handleLogout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.15901 2.90901C2.58097 2.48705 3.15326 2.25 3.75 2.25H9C9.59674 2.25 10.169 2.48705 10.591 2.90901C11.0129 3.33097 11.25 3.90326 11.25 4.5V6C11.25 6.41421 10.9142 6.75 10.5 6.75C10.0858 6.75 9.75 6.41421 9.75 6V4.5C9.75 4.30109 9.67098 4.11032 9.53033 3.96967C9.38968 3.82902 9.19891 3.75 9 3.75H3.75C3.55109 3.75 3.36032 3.82902 3.21967 3.96967C3.07902 4.11032 3 4.30109 3 4.5V13.5C3 13.6989 3.07902 13.8897 3.21967 14.0303C3.36032 14.171 3.55109 14.25 3.75 14.25H9C9.19891 14.25 9.38968 14.171 9.53033 14.0303C9.67098 13.8897 9.75 13.6989 9.75 13.5V12C9.75 11.5858 10.0858 11.25 10.5 11.25C10.9142 11.25 11.25 11.5858 11.25 12V13.5C11.25 14.0967 11.0129 14.669 10.591 15.091C10.169 15.5129 9.59674 15.75 9 15.75H3.75C3.15326 15.75 2.58097 15.5129 2.15901 15.091C1.73705 14.669 1.5 14.0967 1.5 13.5V4.5C1.5 3.90326 1.73705 3.33097 2.15901 2.90901ZM12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967L16.2803 8.46967C16.5732 8.76256 16.5732 9.23744 16.2803 9.53033L14.0303 11.7803C13.7374 12.0732 13.2626 12.0732 12.9697 11.7803C12.6768 11.4874 12.6768 11.0126 12.9697 10.7197L13.9393 9.75H6.75C6.33579 9.75 6 9.41421 6 9C6 8.58579 6.33579 8.25 6.75 8.25H13.9393L12.9697 7.28033C12.6768 6.98744 12.6768 6.51256 12.9697 6.21967Z"
              fill="var(--text-primary)"
            />
          </svg>
          <span>Log-Out</span>
        </button>
      </div>
    </div>
  );
}
