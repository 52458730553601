export const colorsTheme = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '294deg',
  'gradient-primary': '#F2383A',
  'gradient-secondary': '#121212',

  'primary-color': '#B02A37',
  'secondary-color': '#842029',
  'auxiliary-color': '#F2383A',

  'bg-color': '#212529',
  'bg-secondary-color': '#121212',
  'bg-auxiliary-color': '#343A40',
  'bg-circle-color': '#343a40',
  'bg-modal-color': '#343a40',

  'dark-color': '#3F3F3F',

  'text-primary': '#FFFFFF',
  'text-secondary': '#FFFFFF;',
  'text-tertiary': '#FFFFFF;',
  'text-gray': '#4F4F4F',
  
  'input-color': '#FFFFFF',
  'bg-input-color': '#212529',
  'info-color': '#6c757d',
  'disabled-color': '#6c757d',
  'card-color': '#495057',
  'modal-border-color': '#ADB5BD',
  'border-color': '#FFFFFF',
  'bg-login-color': '#9f1b1a',
  'bg-login-page-color': '#9f1b1a',
}